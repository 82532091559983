<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20" style="margin-top: 0px">
        <el-col :span="3">
          <el-input v-model="name" placeholder="客户姓名"></el-input>
        </el-col>
        <el-col :span="4">
          <el-tooltip class="item" effect="dark" content="退款日期" placement="top">
            <el-date-picker
                style="width: 100%"
                v-model="date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                value-format="yyyy-MM-dd"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-tooltip>
        </el-col>
        <el-col :span="3">
          <el-select v-model="refundPayment" placeholder="退款方式" clearable>
            <el-option
                v-for="item in refundPaymentArr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="refundPeople" placeholder="退款人" clearable>
            <el-option
                v-for="item in refundPeopleArr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="queryRfundList" round>搜索</el-button>
          <el-button type="warning" @click="reset" round>重置</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 20px;padding-left: 10px">
      <el-table
          :data="data"
          :row-style="{height:30+'px'}"
          :cell-style="{padding:5+'px'}"
          border
          v-loading="loading"
          element-loading-text="拼命加载中"
          max-height="650"
          :default-sort = "{prop: 'createDate', order: 'descending'}"
          style="width: 99%">
        <el-table-column prop="cusName" label="客户名" align="center" fixed></el-table-column>
        <el-table-column prop="refundProjectsName" label="退单项目" align="center" fixed></el-table-column>
        <el-table-column prop="createDate" align="center" label="退款时间"></el-table-column>
        <el-table-column prop="refundPayment" align="center" label="退款方式"></el-table-column>
<!--        <el-table-column prop="accountNumber" align="center" label="退款账号"></el-table-column>-->
        <el-table-column prop="refundPeopleName" align="center" label="退款人"></el-table-column>
        <el-table-column prop="refundAmount" align="center" label="退款金额"></el-table-column>
        <el-table-column prop="refundTarget" align="center" label="退款目标账户"></el-table-column>
        <el-table-column prop="targetAccount" align="center" label="退款目标账号"></el-table-column>
        <el-table-column  align="center" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <per-button type="primary" size="mini" @click="refundEdit(scope.row)" per="refund:edit">修改</per-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100, 500, 1000]"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <refund-edit ref="refundEdit" :refundPeoples="refundPeopleArr" :refundPayments="refundPaymentArr" @reload="pageInit"></refund-edit>
  </div>
</template>

<script>
import refundEdit from "./refund-edit";
export default {
  name: "refund-list",
  components:{
    refundEdit
  },
  created() {
    this.pageInit();
  },
  data() {
    return {
      name: "",
      date: "",
      refundPayment:"",
      refundPeople: "",
      refundPaymentArr:[],
      refundPeopleArr:[],
      data:[],
      total: 0,
      page: 1,
      limit: 100,
      loading: true,
    }
  },
  methods: {
    //页面初始化
    pageInit: function (){
        this.queryRfundList()
        this.refundPaymentIds()
        this.refundPeopleIds()
    },
    //从后台获取退款方式数组参数
    refundPaymentIds:async function(){
        let result = await this.$selectUtils.refundPaymentIds()
        this.refundPaymentArr = JSON.parse(result.data.data)
    },
    //从后台获取退款人数组参数
    refundPeopleIds:async function (){
      let result = await this.$selectUtils.refundPeopleIds()
      this.refundPeopleArr = JSON.parse(result.data.data)
    },
    //查询退款列表
    queryRfundList:async function (){
      let result = await this.$axios({
        method: "get",
        url: "/refund/queryList",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop"),
          limit: this.limit,
          page: this.page,
          name: this.name,
          date: this.date!=''?this.date.join(" - "):"",
          refundMethodId: this.refundPayment,
          refundPeopleId: this.refundPeople,
        }
      })
    this.loading = false
    this.total = result.data.data.total
    this.data = result.data.data.list
    },
    //重置
    reset:function (){
      this.name = ""
      this.date = ""
      this.refundPayment = ""
      this.refundPeople = ""
      this.refundPaymentArr = []
      this.refundPeopleArr = []
      this.data = []
      this.loading = true
      this.pageInit()
    },
    //退款编辑
    refundEdit:function (row){
      this.$refs.refundEdit.form.id = row.refundId
      this.$refs.refundEdit.form.createDate = row.createDate
      this.$refs.refundEdit.form.refundPeople = row.refundPeopleId
      this.$refs.refundEdit.form.refundPayment = row.refundPaymentId
      this.$refs.refundEdit.form.accountNumber = row.accountNumber
      this.$refs.refundEdit.form.refundTarget = row.refundTarget
      this.$refs.refundEdit.form.targetAccount = row.targetAccount
      this.$refs.refundEdit.form.refundAmount = row.refundAmount
      this.$refs.refundEdit.visible = true
    },
    //分页控制
    handleSizeChange(val) {
      this.limit = val
      this.queryRfundList();
    },
    //分页控制
    handleCurrentChange(val) {
      this.page =val
      this.queryRfundList()
    },
  }
}
</script>

<style scoped>
.el-row {
  margin-top: 15px;
}
</style>

<style scoped>

</style>