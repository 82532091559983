<template>
  <div>
    <el-dialog
        title="退款修改界面"
        width="40%"
        :visible.sync="visible"
        :modal-append-to-body="false"
        center
    >
      <el-row>
        <el-form label-width="130px" style="margin-top: 10px;margin-left: 10px" :rules="rules" :model="form" ref="form">
          <el-row :gutter="15">
            <el-col :span="10">
              <el-form-item :label="label.targetAccount" prop="targetAccount">
                <el-input v-model="form.targetAccount" placeholder="请填写退款目标账号" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item :label="label.refundPeople" prop="refundPeople">
                <el-select v-model="form.refundPeople" placeholder="请选择退款人" clearable>
                  <el-option
                      v-for="item in refundPeoples"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :span="10">
              <el-form-item :label="label.refundTarget" prop="refundTarget">
                <el-input v-model="form.refundTarget" placeholder="请填写退款目标账户" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item :label="label.accountNumber" prop="accountNumber">
                <el-input v-model="form.accountNumber" placeholder="请填写退款账号" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :span="10">
              <el-form-item :label="label.refundPayment" prop="refundPayment">
                <el-select v-model="form.refundPayment" placeholder="请选择退款方式" clearable>
                  <el-option
                      v-for="item in refundPayments"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item :label="label.createDate" prop="createDate">
                <el-date-picker
                    style="width: 100%"
                    v-model="form.createDate"
                    value-format="yyyy-MM-dd"
                    clearable
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item :label="label.refundAmount" prop="refundAmount">
                <el-input v-model="form.refundAmount" placeholder="请输入实退金额" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click='onsubmit("form")'>提 交</el-button>
        <el-button @click="visible=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "refund-edit",
  data() {
    return {
      label:{
        targetAccount:"退款目标账号:",
        accountNumber:"退款账号:",
        refundPayment:"退款方式:",
        createDate:"退款日期:",
        refundPeople:"退款人:",
        refundAmount:"实退金额(负数):",
        refundTarget:"退款目标账户:"
      },
      visible: false,
      form: {
        id: "",
        createDate: "",
        refundPeople: "",
        refundPayment: "",
        accountNumber: "",
        refundTarget: "",
        targetAccount: "",
        refundAmount: "",
      },
      rules: {
        createDate: [{required: true, message: '请选择创建时间', trigger: "change"}],
        refundPeople: [{required: true, message: '请选择退款人', trigger: "change"}],
        refundPayment: [{required: true, message: '请选择退款方式', trigger: "change"}],
        accountNumber: [{required: true, message: '请填写退款账号', trigger: "blur"}],
        refundTarget: [{required: true, message: '请填写退款目标账户', trigger: "blur"}],
        targetAccount: [{required: true, message: '请填写退款目标账号', trigger: "blur"}],
        refundAmount: [{required: true, message: '请填写退款金额', trigger: "blur"}],
      },
    }
  },
  props: {
    refundPeoples: {
      type: Array,
      required: true,
      default() {
        return {}
      }
    },
    refundPayments: {
      type: Array,
      required: true,
      default() {
        return {}
      }
    }
  },
  methods: {
    //退款提交方法
    onsubmit: function (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let msg = '';
          let obj = Object.assign({}, this.form);
          obj.refundPayment = this.refundPayments.filter(s=>s.value == obj.refundPayment)[0].name
          obj.refundPeople =  this.refundPeoples.filter(s=>s.value == obj.refundPeople)[0].name
          Object.keys(this.form).forEach(
              s =>{
                if(s !="id"){
                  msg += `<p><span>${this.label[s]}</span><span style="color: darkred">${obj[s]}</span></p>`
                }
              }
          )
          this.$confirm(msg, '信息确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true,
            dangerouslyUseHTMLString:true
          }).then(async () => {
            let result = await this.$axios({
              method: "post",
              url: "/refund/updateRefund",
              params: this.form
            })
            if (result.data.code === 200) {
              this.$message({
                message: '退款信息修改成功!',
                type: 'success'
              });
              this.reload();
              this.visible = false
            } else {
              this.$message({
                message: '退款信息修改失败!',
                type: 'warning'
              });
            }
          }).catch(() => {
          });
        } else {
          this.$message({
            message: '请填写或选择必填项',
            type: 'error'
          });
          return false;
        }
      })
    },
    //页面刷新
    reload: function () {
      this.$emit("reload");
    }
  }
}
</script>

<style scoped>

</style>